<template>
  <div>
    <van-nav-bar title="设备报修页面" fixed border/>

  </div>
</template>

<script setup>
import {onBeforeMount, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {copy} from "@/plugin/clipboard";
import {Toast} from "vant";
import {isCreate, requestWorkorderProgressing, requestWorkorderUnconverted} from "../../api/workorder";
import {queryDevice} from "../../api/device";
import dd from "gdt-jsapi";

onBeforeMount(validationUrlParamStrictly);
const urlParam = getUrlParam();
const router = useRouter();

const deviceId = ref(urlParam.id);
const tenant = ref(urlParam.tenant);
const isEmptyId = ref(false);
const query = getUrlParam();

window.location.href = `https://ai.xcfw.cn/ui/chat/54fd2165ffbf666d?tenant=${query.tenant}&id=${query.id}`

onMounted(()=>{
  dd.showTitleBar()
});
</script>

<style scoped>

</style>
